import type { AuthError } from 'firebase/auth';

export const formatAuthError = (error: AuthError): string => {
  // Log the error for debugging
  console.error('Auth Error:', error);
  
  switch (error.code) {
    case 'auth/invalid-email':
      return 'Please enter a valid email address.';
    case 'auth/user-disabled':
      return 'This account has been disabled. Please contact support.';
    case 'auth/user-not-found':
      return 'No account found with this email address.';
    case 'auth/wrong-password':
      return 'Incorrect password. Please try again.';
    case 'auth/email-already-in-use':
      return 'An account already exists with this email address.';
    case 'auth/weak-password':
      return 'Please choose a stronger password (at least 6 characters).';
    case 'auth/popup-closed-by-user':
      return 'The sign-in window was closed. Please try again.';
    case 'auth/cancelled-popup-request':
      return 'The sign-in was cancelled. Please try again.';
    case 'auth/popup-blocked':
      return 'The sign-in window was blocked by a popup blocker. Please allow popups for this site.';
    case 'auth/network-request-failed':
      return 'Network error. Please check your internet connection and try again.';
    case 'auth/operation-not-allowed':
      return 'Google sign-in is not enabled. Please contact support.';
    case 'auth/internal-error':
      return 'An internal error occurred. Please try again later.';
    default:
      return `Authentication error: ${error.message || 'Please try again.'}`;
  }
};