import { AITool } from '../../types';

export const phase2Tools: AITool[] = [
  {
    id: 'seekario',
    name: 'Seekario',
    description: 'Seekario simplifies your job search with user-friendly features and AI-powered tools.',
    category: 'Phase 2: Job Search',
    icon: 'search',
    url: 'https://seekario.ai',
    features: [
      'AI Role Assessment Wizard',
      'AI Resume Builder',
      'Customized application materials'
    ],
    phase: 2
  },
  {
    id: 'wobo-ai',
    name: 'Wobo AI',
    description: 'Wobo AI helps you find opportunities and create compelling applications.',
    category: 'Phase 2: Job Search',
    icon: 'search',
    url: 'https://www.wobo.ai',
    features: [
      'AI job search assistant',
      'AI cover letter generator',
      'ATS-friendly resume creation'
    ],
    phase: 2
  },
  {
    id: 'jobseer',
    name: 'Jobseer',
    description: 'Jobseer analyzes job descriptions and matches your skills to suitable postings.',
    category: 'Phase 2: Job Search',
    icon: 'search',
    url: 'https://www.jobseer.ai',
    features: [
      'Job matching',
      'Fit score generation',
      'Skill gap identification'
    ],
    phase: 2
  },
  {
    id: 'torre',
    name: 'Torre',
    description: 'Torre is an AI-driven recruiting platform that automates candidate matching.',
    category: 'Phase 2: Job Search',
    icon: 'search',
    url: 'https://www.torre.ai',
    features: [
      'Automated matching',
      'Detailed profiles',
      'Suitability predictions'
    ],
    phase: 2
  }
];