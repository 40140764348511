import {
  Lightbulb,
  FileText,
  Search,
  Handshake,
  MessageSquare,
  PenTool,
  Building,
  type LucideIcon
} from 'lucide-react';

export const iconMap: Record<string, LucideIcon> = {
  'lightbulb': Lightbulb,
  'file-text': FileText,
  'search': Search,
  'handshake': Handshake,
  'message-square': MessageSquare,
  'pen-tool': PenTool,
  'building': Building
};