import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import type { User } from 'firebase/auth';
import { validateRatingSubmission } from '../utils/validation';

interface Rating {
  toolId: string;
  userId: string;
  rating: number;
  timestamp: number;
}

interface RatingState {
  ratings: Rating[];
  addRating: (toolId: string, rating: number, user: User) => void;
  getRating: (toolId: string, userId: string) => number | null;
  getAverageRating: (toolId: string) => number;
  getRatingsCount: (toolId: string) => number;
}

export const useRatingStore = create<RatingState>()(
  persist(
    (set, get) => ({
      ratings: [],

      addRating: (toolId, rating, user) => {
        const validation = validateRatingSubmission(toolId, rating, user);
        if (!validation.isValid) {
          console.error(validation.error);
          return;
        }

        const { ratings } = get();
        const newRating: Rating = {
          toolId,
          userId: user.uid,
          rating,
          timestamp: Date.now()
        };

        const updatedRatings = ratings.filter(
          r => !(r.toolId === toolId && r.userId === user.uid)
        );

        set({ ratings: [...updatedRatings, newRating] });
      },

      getRating: (toolId, userId) => {
        const { ratings } = get();
        const rating = ratings.find(
          r => r.toolId === toolId && r.userId === userId
        );
        return rating ? rating.rating : null;
      },

      getAverageRating: (toolId) => {
        const { ratings } = get();
        const toolRatings = ratings.filter(r => r.toolId === toolId);
        if (toolRatings.length === 0) return 0;
        
        const sum = toolRatings.reduce((acc, curr) => acc + curr.rating, 0);
        return sum / toolRatings.length;
      },

      getRatingsCount: (toolId) => {
        const { ratings } = get();
        return ratings.filter(r => r.toolId === toolId).length;
      }
    }),
    {
      name: 'tool-ratings',
      partialize: (state) => ({ ratings: state.ratings })
    }
  )
);