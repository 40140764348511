import React from 'react';
import { motion } from 'framer-motion';
import { Trash2 } from 'lucide-react';
import { useToolStore } from '@/features/tools';
import { aiTools } from '@/data/tools';
import { DiscardedToolCard } from './DiscardedToolCard';

export function DiscardedToolsList() {
  const { hiddenTools } = useToolStore();
  const discardedToolsData = aiTools.filter(tool => hiddenTools.includes(tool.id));

  if (discardedToolsData.length === 0) {
    return (
      <motion.div 
        className="text-center py-16"
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.6 }}
      >
        <div className="flex justify-center mb-4">
          <div className="p-4 rounded-full bg-gray-100">
            <Trash2 className="w-8 h-8 text-gray-400" />
          </div>
        </div>
        <h3 className="text-xl font-semibold text-gray-900 mb-2">No discarded tools</h3>
        <p className="text-gray-600">
          Tools you hide will appear here. You can restore them at any time.
        </p>
      </motion.div>
    );
  }

  return (
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
      {discardedToolsData.map((tool, index) => (
        <DiscardedToolCard key={tool.id} tool={tool} index={index} />
      ))}
    </div>
  );
}