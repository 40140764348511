import React from 'react';
import { motion } from 'framer-motion';
import { Star } from 'lucide-react';
import { useAuthStore } from '@/features/auth';
import { useRatingStore } from '../store/ratingStore';

interface RatingStarsProps {
  toolId: string;
  onAuthRequired: () => void;
  size?: 'sm' | 'md' | 'lg';
}

export function RatingStars({ toolId, onAuthRequired, size = 'md' }: RatingStarsProps) {
  const { user } = useAuthStore();
  const { getRating, addRating } = useRatingStore();
  const [hoveredRating, setHoveredRating] = React.useState<number | null>(null);
  
  const currentRating = user ? getRating(toolId, user.uid) || 0 : 0;

  const starSizes = {
    sm: 'w-4 h-4',
    md: 'w-5 h-5',
    lg: 'w-6 h-6'
  };

  if (!user) {
    return (
      <button
        onClick={onAuthRequired}
        className="text-sm text-gray-500 hover:text-gray-700 transition-colors"
      >
        Sign in to rate this tool
      </button>
    );
  }

  return (
    <div className="flex items-center gap-1">
      {[1, 2, 3, 4, 5].map((star) => (
        <motion.button
          key={star}
          whileHover={{ scale: 1.1 }}
          whileTap={{ scale: 0.95 }}
          onClick={() => addRating(toolId, star, user)}
          onMouseEnter={() => setHoveredRating(star)}
          onMouseLeave={() => setHoveredRating(null)}
          className="focus:outline-none"
        >
          <Star
            className={`${starSizes[size]} transition-colors ${
              star <= (hoveredRating || currentRating)
                ? 'text-yellow-400 fill-current'
                : 'text-gray-300'
            }`}
          />
        </motion.button>
      ))}
    </div>
  );
}