import { phase0Tools } from './phase0';
import { phase1Tools } from './phase1';
import { phase2Tools } from './phase2';
import { phase3Tools } from './phase3';
import { phase4Tools } from './phase4';
import type { AITool } from '../../types';

export const aiTools: AITool[] = [
  ...phase0Tools,
  ...phase1Tools,
  ...phase2Tools,
  ...phase3Tools,
  ...phase4Tools
].sort((a, b) => a.phase - b.phase);