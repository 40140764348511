import { AITool } from '../../types';

export const phase4Tools: AITool[] = [
  {
    id: 'interview-copilot',
    name: 'Interview Copilot',
    description: 'AI-powered tool for real-time interview assistance.',
    category: 'Phase 4: Interview',
    icon: 'message-square',
    url: 'https://interviewcopilot.io',
    features: [
      'Real-time answer generation',
      'Interview simulation',
      'Performance feedback'
    ],
    phase: 4
  },
  {
    id: 'bossed-ai',
    name: 'Bossed AI',
    description: 'Voice-interactive interview simulator with AI feedback.',
    category: 'Phase 4: Interview',
    icon: 'message-square',
    url: 'https://bossed.ai',
    features: [
      'Voice interaction',
      'Personalized feedback',
      'Progress tracking'
    ],
    phase: 4
  },
  {
    id: 'greet-ai',
    name: 'GreetAI',
    description: 'Practice interviews with AI and get instant feedback.',
    category: 'Phase 4: Interview',
    icon: 'message-square',
    url: 'https://www.greetai.co/practice',
    features: [
      'Interview simulation',
      'Expression analysis',
      'Progress monitoring'
    ],
    phase: 4
  },
  {
    id: 'mock-interviewer-ai',
    name: 'Mock Interviewer AI',
    description: 'Real-time voice interviews with comprehensive feedback.',
    category: 'Phase 4: Interview',
    icon: 'message-square',
    url: 'https://www.mockinterviewer.ai',
    features: [
      'Voice simulation',
      'Performance scoring',
      'Detailed feedback'
    ],
    phase: 4
  }
];