import React from 'react';
import { NavLink } from 'react-router-dom';
import { motion } from 'framer-motion';
import { Bookmark } from 'lucide-react';
import type { User } from 'firebase/auth';

interface MobileMenuProps {
  isOpen: boolean;
  user: User | null;
  onClose: () => void;
  onLogout: () => void;
  onShowAuth: () => void;
}

export function MobileMenu({ isOpen, user, onClose, onLogout, onShowAuth }: MobileMenuProps) {
  return (
    <motion.div
      className={`md:hidden ${isOpen ? 'block' : 'hidden'}`}
      initial={{ opacity: 0, height: 0 }}
      animate={{ opacity: isOpen ? 1 : 0, height: isOpen ? 'auto' : 0 }}
      transition={{ duration: 0.2 }}
    >
      <div className="border-t border-gray-100 py-2 px-4 space-y-1 bg-white">
        {[
          { to: '/', label: 'Home' },
          { to: '/blogs', label: 'Blogs' },
          { to: '/future-phases', label: 'Future Phases' },
          { to: '/about', label: 'About' }
        ].map(({ to, label }) => (
          <NavLink
            key={to}
            to={to}
            className={({ isActive }) =>
              `block px-3 py-2 text-base font-medium rounded-lg transition-colors duration-200 ${
                isActive
                  ? 'text-indigo-600 bg-indigo-50'
                  : 'text-gray-600 hover:text-gray-900 hover:bg-gray-50'
              }`
            }
            onClick={onClose}
          >
            {label}
          </NavLink>
        ))}

        {user ? (
          <>
            <NavLink
              to="/saved-tools"
              className="flex items-center gap-2 px-3 py-2 text-base font-medium text-gray-600 hover:text-gray-900 hover:bg-gray-50 rounded-lg"
              onClick={onClose}
            >
              <Bookmark className="w-4 h-4" />
              Saved Tools
            </NavLink>
            <button
              onClick={onLogout}
              className="w-full px-3 py-2 text-left text-base font-medium text-red-600 hover:bg-red-50 rounded-lg"
            >
              Sign Out
            </button>
          </>
        ) : (
          <button
            onClick={onShowAuth}
            className="w-full px-3 py-2 text-left text-base font-medium text-indigo-600 hover:bg-indigo-50 rounded-lg"
          >
            Sign In
          </button>
        )}
      </div>
    </motion.div>
  );
}