import React, { useEffect, useState } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { onAuthStateChanged } from 'firebase/auth';
import { auth } from './lib/firebase';
import { useAuthStore } from './store';
import { Navigation } from './components/Navigation';
import { HomePage } from './pages/HomePage';
import { BlogsPage } from './pages/BlogsPage';
import { BlogArticlePage } from './pages/BlogArticlePage';
import { FuturePhasesPage } from './pages/FuturePhasesPage';
import { AboutPage } from './pages/AboutPage';
import { SavedToolsPage } from './pages/SavedToolsPage';
import { ErrorBoundary } from './components/ErrorBoundary';
import { SEO } from './components/SEO';
import { LoadingScreen } from './components/LoadingScreen';

function ProtectedRoute({ children }: { children: React.ReactNode }) {
  const { user } = useAuthStore();
  return user ? <>{children}</> : <Navigate to="/" replace />;
}

function App() {
  const { setUser } = useAuthStore();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setUser(user);
      setIsLoading(false);
    });

    return () => unsubscribe();
  }, [setUser]);

  if (isLoading) {
    return <LoadingScreen />;
  }

  return (
    <ErrorBoundary>
      <SEO />
      <Navigation />
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/blogs" element={<BlogsPage />} />
        <Route path="/blogs/:id" element={<BlogArticlePage />} />
        <Route path="/future-phases" element={<FuturePhasesPage />} />
        <Route path="/about" element={<AboutPage />} />
        <Route 
          path="/saved-tools" 
          element={
            <ProtectedRoute>
              <SavedToolsPage />
            </ProtectedRoute>
          } 
        />
      </Routes>
    </ErrorBoundary>
  );
}

export default App;