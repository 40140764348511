import React from 'react';
import { motion } from 'framer-motion';
import { BlogsSection } from '../components/BlogsSection';
import { Footer } from '../components/Footer';

export function BlogsPage() {
  return (
    <div className="min-h-screen bg-gray-50 pt-16">
      <motion.div 
        className="py-16 bg-gradient-to-br from-indigo-600 to-blue-600 text-white"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.6 }}
      >
        <div className="container mx-auto px-4">
          <h1 className="text-4xl font-bold text-center">Latest Insights</h1>
          <p className="mt-4 text-lg text-center text-blue-100 max-w-2xl mx-auto">
            Explore our collection of articles on AI technology, career development, and industry trends.
          </p>
        </div>
      </motion.div>

      <BlogsSection />
      
      <Footer />
    </div>
  );
}