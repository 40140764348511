import React from 'react';
import { useParams, Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import { ArrowLeft } from 'lucide-react';
import { blogs } from '../data/blogs';
import { aiTools } from '../data/tools';
import { Footer } from '../components/Footer';

export function BlogArticlePage() {
  const { id } = useParams();
  const blog = blogs.find(b => b.id === id);

  if (!blog) {
    return (
      <div className="min-h-screen bg-gray-50 pt-12 md:pt-14">
        <div className="container mx-auto px-4 py-16">
          <p className="text-gray-600 text-center">Blog post not found.</p>
        </div>
      </div>
    );
  }

  const getToolLink = (toolName: string) => {
    const tool = aiTools.find(t => 
      t.name.toLowerCase() === toolName.toLowerCase() ||
      t.name.toLowerCase().replace(/\s+/g, '') === toolName.toLowerCase().replace(/\s+/g, '')
    );
    return tool?.url || `https://www.${toolName.toLowerCase().replace(/\s+/g, '')}.com`;
  };

  const renderToolLink = (name: string) => (
    <a 
      href={getToolLink(name)}
      target="_blank"
      rel="noopener noreferrer"
      className="text-indigo-600 hover:text-indigo-700 font-medium"
    >
      {name}
    </a>
  );

  const renderBlogContent = () => {
    switch (id) {
      case 'ai-job-search-2024':
        return (
          <>
            <p>The job market is evolving rapidly, and AI is transforming how job seekers find and secure opportunities. From tailoring resumes to identifying the right roles, AI tools are revolutionizing every stage of the job search process. Here's how you can harness these innovations to land your dream job in 2024.</p>

            <h2>1. Build a Tailored Resume with AI</h2>
            <p>Creating an ATS-friendly resume is crucial in today's competitive landscape. Tools like {renderToolLink('Teal')} and {renderToolLink('WonsultingAI')} help optimize your resume by analyzing job descriptions and tailoring keywords to match. This ensures your application stands out to recruiters and automated systems.</p>

            <h2>2. Find the Perfect Job Match</h2>
            <p>Searching for the right roles can be daunting. AI-powered platforms like {renderToolLink('Jobseer')} and {renderToolLink('Torre')} simplify this process by analyzing your skills and preferences to recommend tailored job listings. LinkedIn's AI features take it further, offering personalized job suggestions based on your profile.</p>

            <h2>3. Master Networking with AI</h2>
            <p>Building connections is key to job search success. Tools like {renderToolLink('Crystal')} help you craft personalized messages by analyzing potential contacts' personalities, while {renderToolLink('Lunchclub')} connects you with professionals who align with your career goals.</p>

            <h2>4. Ace Your Interviews</h2>
            <p>Preparation is vital, and AI can help you practice effectively. Tools like {renderToolLink('Huru')} and {renderToolLink('Bossed AI')} simulate realistic interviews, providing feedback on your answers to refine your responses and boost your confidence.</p>

            <h2>The Future of Job Searching</h2>
            <p>With AI, job seekers can navigate the hiring process more efficiently and strategically. By leveraging these tools, you'll not only save time but also increase your chances of success. Explore these AI resources today and take the first step toward your dream career.</p>
          </>
        );

      case 'interview-preparation':
        return (
          <>
            <p>Preparing for an interview can be nerve-wracking, but AI tools are here to make the process smoother and more effective. From simulating mock interviews to refining your answers, these innovations are transforming how candidates get ready for the big day.</p>

            <h2>1. Practice with Realistic Mock Interviews</h2>
            <p>AI-driven platforms like {renderToolLink('Bossed AI')} and {renderToolLink('Mock Interviewer AI')} offer realistic, voice-interactive interview simulations. These tools mimic real-life scenarios, allowing you to practice answering job-specific questions while receiving instant feedback on your performance.</p>

            <h2>2. Refine Your Responses with AI Feedback</h2>
            <p>Tools like {renderToolLink('Huru')} analyze your answers in real-time, highlighting areas for improvement and helping you craft more concise and impactful responses. Whether you're preparing for technical or behavioral interviews, AI feedback ensures you're well-equipped.</p>

            <h2>3. Get Personalized Question Sets</h2>
            <p>AI tools such as {renderToolLink('Interviews by AI')} and {renderToolLink('Interview Copilot')} generate tailored question sets based on the specific job description or industry you're targeting. This level of customization ensures that your preparation aligns closely with what interviewers are likely to ask.</p>

            <h2>4. Build Confidence and Track Progress</h2>
            <p>Platforms like {renderToolLink('GreetAI')} track your progress over time, showing how your answers and confidence improve with each practice session. Knowing that you're better prepared can significantly boost your confidence during the actual interview.</p>

            <h2>The Future of Interview Preparation</h2>
            <p>AI tools are reshaping how candidates approach interviews, making preparation more efficient and personalized. By leveraging these technologies, you can enter your next interview feeling confident, prepared, and ready to impress.</p>
          </>
        );

      case 'networking-ai':
        return (
          <>
            <p>Networking is one of the most powerful tools for career growth, but it can be time-consuming and challenging. AI is changing the game by helping professionals identify key connections, craft personalized outreach, and build meaningful relationships.</p>

            <h2>1. Find the Right People to Connect With</h2>
            <p>AI tools like {renderToolLink('Jobseer')} and {renderToolLink('LinkedIn Sales Navigator')} analyze your profile and career goals to identify the most relevant professionals in your industry. By focusing on the right people, you can streamline your networking efforts and maximize your impact.</p>

            <h2>2. Craft Tailored Outreach Messages</h2>
            <p>Generic messages rarely make an impression. Tools like {renderToolLink('Crystal')} use AI to analyze a contact's personality and suggest messaging styles that resonate with them. {renderToolLink('Dux-Soup')} takes it further by automating personalized LinkedIn messages and follow-ups, saving you time while improving engagement.</p>

            <h2>3. Automate Your Networking Efforts</h2>
            <p>Platforms like {renderToolLink('Zopto')} and {renderToolLink('Octopus CRM')} allow you to manage and scale your LinkedIn outreach campaigns. These tools keep track of your connections, automate follow-ups, and provide analytics to show what's working, so you can refine your approach.</p>

            <h2>4. Build Meaningful Relationships</h2>
            <p>AI isn't just about efficiency - it's about fostering real connections. Tools like {renderToolLink('Lunchclub')} match you with professionals who share your goals and interests, enabling authentic and productive conversations.</p>

            <h2>The Future of Networking</h2>
            <p>AI is making it easier than ever to connect with the right people, maintain meaningful relationships, and unlock career opportunities. Whether you're seeking referrals, mentorship, or collaboration, leveraging AI tools can transform your professional networking strategy.</p>
          </>
        );

      default:
        return <p>Content not found.</p>;
    }
  };

  return (
    <div className="min-h-screen bg-gray-50">
      <div className="fixed top-0 left-0 right-0 z-50 bg-gradient-to-br from-indigo-600 to-blue-600">
        <div className="container mx-auto px-4 h-12 md:h-14 flex items-center">
          <Link 
            to="/blogs"
            className="inline-flex items-center gap-1.5 text-white/90 hover:text-white transition-colors duration-200 group"
          >
            <motion.div
              whileHover={{ x: -2 }}
              transition={{ duration: 0.2 }}
            >
              <ArrowLeft className="w-4 h-4" />
            </motion.div>
            <span className="text-xs md:text-sm font-medium">Back to Blogs</span>
          </Link>
        </div>
      </div>

      <div className="pt-24 md:pt-32 pb-12 md:pb-16 bg-gradient-to-br from-indigo-600 to-blue-600 text-white">
        <div className="container mx-auto px-4">
          <span className="inline-block px-2 py-0.5 rounded-full text-xs font-medium bg-white/10 text-white mb-3">
            {blog.category}
          </span>
          <h1 className="text-2xl md:text-4xl font-bold mb-3">{blog.title}</h1>
          <p className="text-sm text-blue-100">{blog.date}</p>
        </div>
      </div>

      <article className="py-12 md:py-16">
        <div className="container mx-auto px-4">
          <div className="max-w-3xl mx-auto bg-white rounded-xl shadow-sm overflow-hidden">
            <div className="relative h-48 md:h-64 lg:h-72">
              <img 
                src={blog.image} 
                alt={blog.title}
                className="absolute inset-0 w-full h-full object-cover"
              />
            </div>
            <div className="p-6 md:p-8 lg:p-10">
              <div className="prose prose-sm md:prose-base lg:prose-lg prose-indigo max-w-none">
                {renderBlogContent()}
              </div>
            </div>
          </div>
        </div>
      </article>

      <Footer />
    </div>
  );
}