import React from 'react';
import { motion } from 'framer-motion';
import { FuturePhases } from '../components/FuturePhases';
import { Footer } from '../components/Footer';

export function FuturePhasesPage() {
  return (
    <div className="min-h-screen bg-gray-50 pt-16">
      <motion.div 
        className="py-16 md:py-24 bg-gradient-to-br from-indigo-600 to-blue-600 text-white"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.6 }}
      >
        <div className="container mx-auto px-4">
          <h1 className="text-4xl md:text-6xl lg:text-7xl font-bold text-center mb-6">
            Future Phases
          </h1>
          <p className="mt-4 text-lg md:text-xl lg:text-2xl text-center text-blue-100 max-w-3xl mx-auto leading-relaxed">
            Explore upcoming AI tools and features we're developing to enhance your career journey.
          </p>
        </div>
      </motion.div>

      <FuturePhases />
      
      <Footer />
    </div>
  );
}