import React from 'react';
import { TypeAnimation } from 'react-type-animation';
import { motion } from 'framer-motion';

export function HeroContent() {
  return (
    <div className="max-w-6xl mx-auto text-center space-y-8 md:space-y-12 px-4">
      <motion.div 
        className="text-4xl sm:text-5xl md:text-7xl lg:text-8xl xl:text-9xl font-bold text-center mb-6 md:mb-10 bg-clip-text text-transparent bg-gradient-to-r from-white to-blue-100"
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.8 }}
      >
        <TypeAnimation
          sequence={[
            'Progressly.ai',
            1000,
            'AI-Powered Career Growth',
            2000,
          ]}
          wrapper="h1"
          speed={50}
          repeat={Infinity}
          cursor={true}
          className="!leading-tight"
        />
      </motion.div>
      
      <motion.h2 
        className="text-lg sm:text-xl md:text-2xl lg:text-3xl font-medium tracking-wide text-blue-50/90 max-w-3xl mx-auto"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.8, delay: 1.5 }}
      >
        Discover AI tools that power your career growth.
      </motion.h2>
    </div>
  );
}