import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuthStore } from '@/store';
import { AuthModal } from '../AuthModal';
import { NavigationLinks } from './NavigationLinks';
import { UserMenu } from './UserMenu';
import { MobileMenu } from './MobileMenu';
import { Logo } from './Logo';
import { MobileMenuToggle } from './MobileMenuToggle';

export function Navigation() {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [showAuthModal, setShowAuthModal] = useState(false);
    const navigate = useNavigate();
    const { user, logout } = useAuthStore();

    const handleLogoClick = (e: React.MouseEvent) => {
        e.preventDefault();
        navigate('/');
        setIsMenuOpen(false);
    };

    const handleLogout = async () => {
        await logout();
        navigate('/');
    };

    return (
        <>
            <nav className="fixed top-0 left-0 right-0 z-50 bg-white/95 backdrop-blur-md shadow-sm">
                <div className="container mx-auto px-4">
                    <div className="flex items-center justify-between h-14 md:h-16 lg:h-20">
                        <Logo onNavigate={handleLogoClick} />

                        <div className="hidden md:flex items-center space-x-6 lg:space-x-8">
                            <NavigationLinks />
                            <UserMenu
                                user={user}
                                onLogout={handleLogout}
                                onShowAuth={() => setShowAuthModal(true)}
                            />
                        </div>

                        <MobileMenuToggle 
                            isOpen={isMenuOpen}
                            onToggle={() => setIsMenuOpen(!isMenuOpen)}
                        />
                    </div>
                </div>

                <MobileMenu
                    isOpen={isMenuOpen}
                    user={user}
                    onClose={() => setIsMenuOpen(false)}
                    onLogout={handleLogout}
                    onShowAuth={() => {
                        setShowAuthModal(true);
                        setIsMenuOpen(false);
                    }}
                />
            </nav>

            <AuthModal
                isOpen={showAuthModal}
                onClose={() => setShowAuthModal(false)}
            />
        </>
    );
}