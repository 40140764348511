import React from 'react';
import { Header } from '../components/Header';
import { HotPicks } from '../components/HotPicks';
import { ToolsSection } from '../components/Home/ToolsSection';
import { Footer } from '../components/Footer';

export function HomePage() {
  return (
    <div className="min-h-screen bg-gray-50">
      <Header />
      <HotPicks />
      <ToolsSection />
      <Footer />
    </div>
  );
}