import React from 'react';

export function Footer() {
  return (
    <footer className="bg-gray-900 text-white py-12">
      <div className="container mx-auto px-4">
        <p className="text-gray-400 text-center text-sm">
          © {new Date().getFullYear()} Progressly.ai. All rights reserved.
        </p>
      </div>
    </footer>
  );
}