import React from 'react';
import { NavLink } from 'react-router-dom';

export function NavigationLinks() {
  const links = [
    { to: '/', label: 'Home' },
    { to: '/blogs', label: 'Blogs' },
    { to: '/future-phases', label: 'Future Phases' },
    { to: '/about', label: 'About' }
  ];

  return (
    <>
      {links.map(({ to, label }) => (
        <NavLink
          key={to}
          to={to}
          className={({ isActive }) =>
            `text-sm lg:text-base font-medium tracking-wide transition-colors duration-200 ${
              isActive 
                ? 'text-indigo-600' 
                : 'text-gray-600 hover:text-gray-900'
            }`
          }
        >
          {label}
        </NavLink>
      ))}
    </>
  );
}