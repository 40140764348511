import { AITool } from '../../types';

export const phase3Tools: AITool[] = [
  {
    id: 'networking-ai',
    name: 'Networking AI',
    description: 'Networking AI simplifies your networking efforts with AI-powered tools.',
    category: 'Phase 3: Networking',
    icon: 'handshake',
    url: 'https://networkingai.org',
    features: [
      'AI-generated messaging',
      'LinkedIn profile optimization',
      'Network analysis'
    ],
    phase: 3
  },
  {
    id: 'dux-soup',
    name: 'Dux-Soup',
    description: 'LinkedIn automation tool for finding and engaging with prospects.',
    category: 'Phase 3: Networking',
    icon: 'handshake',
    url: 'https://www.dux-soup.com/linkedin-automation?fpr=progressly',
    features: [
      'Automated profile visits',
      'Connection requests',
      'Personalized messaging'
    ],
    phase: 3
  },
  {
    id: 'crystal',
    name: 'Crystal',
    description: 'AI-powered personality insights for better communication.',
    category: 'Phase 3: Networking',
    icon: 'handshake',
    url: 'https://www.crystalknows.com',
    features: [
      'Personality assessments',
      'Communication advice',
      'Email templates'
    ],
    phase: 3
  },
  {
    id: 'lunchclub',
    name: 'Lunchclub',
    description: 'AI-powered professional networking platform.',
    category: 'Phase 3: Networking',
    icon: 'handshake',
    url: 'https://lunchclub.com',
    features: [
      'AI-driven matching',
      'Virtual meetings',
      'Interest alignment'
    ],
    phase: 3
  }
];