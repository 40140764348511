import { AITool } from '../../types';

export const phase1Tools: AITool[] = [
  {
    id: 'resume-io',
    name: 'Resume.io',
    description: 'Professional resume builder with AI-powered content suggestions and ATS optimization.',
    category: 'Phase 1: Resume',
    icon: 'file-text',
    url: 'https://resume.io',
    features: [
      'AI content suggestions',
      'ATS optimization',
      'Professional templates'
    ],
    phase: 1
  },
  {
    id: 'resume-check',
    name: 'Resume Check',
    description: 'A platform that assists in resume optimization, ensuring ATS compatibility and highlighting key skills.',
    category: 'Phase 1: Resume',
    icon: 'file-text',
    url: 'https://resumecheck.net',
    features: [
      'Resume analysis',
      'Keyword optimization',
      'ATS compatibility checks'
    ],
    phase: 1
  },
  {
    id: 'sudowrite',
    name: 'Sudowrite',
    description: 'An AI tool for fast and effortless content creation, which can be used for crafting professional resumes and cover letters.',
    category: 'Phase 1: Resume',
    icon: 'file-text',
    url: 'https://www.sudowrite.com',
    features: [
      'AI-powered writing assistant',
      'Customizable templates',
      'Grammar and style suggestions'
    ],
    phase: 1
  },
  {
    id: 'pro-photos-ai',
    name: 'Pro Photos AI',
    description: 'AI-powered headshot generator to create professional, polished photos suitable for resume profiles or LinkedIn.',
    category: 'Phase 1: Resume',
    icon: 'file-text',
    url: 'https://prophotos.ai',
    features: [
      'AI-driven photo enhancement',
      'Automatic background removal',
      'Style customization'
    ],
    phase: 1
  },
  {
    id: 'huru-resume',
    name: 'Huru',
    description: 'An AI-powered tool for interview preparation, including resume review to optimize documents for specific jobs.',
    category: 'Phase 1: Resume',
    icon: 'file-text',
    url: 'https://huru.ai',
    features: [
      'Resume optimization',
      'Customizable templates',
      'Mock interview preparation'
    ],
    phase: 1
  }
];