import React from 'react';
import { motion } from 'framer-motion';
import { AITool } from '../types';
import { ToolCard } from './ToolCard';

interface MainContentProps {
  tools: AITool[];
  onToolHide?: (toolId: string) => void;
}

export function MainContent({ tools, onToolHide }: MainContentProps) {
  if (!tools.length) {
    return (
      <div className="container mx-auto px-4 py-12">
        <p className="text-center text-gray-600">No tools found for this category.</p>
      </div>
    );
  }

  return (
    <main className="container mx-auto px-4 py-8 md:py-12">
      <motion.div 
        className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 md:gap-8"
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.6 }}
      >
        {tools.map((tool, index) => (
          <ToolCard 
            key={tool.id} 
            tool={tool} 
            index={index} 
            onToolHide={onToolHide} 
          />
        ))}
      </motion.div>
    </main>
  );
}