import { 
  signInWithEmailAndPassword, 
  createUserWithEmailAndPassword,
  signInWithPopup,
  signOut,
  type Auth,
  type AuthProvider,
  type UserCredential
} from 'firebase/auth';
import type { AuthResult } from '../types/auth.types';
import { logAuthError } from '../utils/errorHandling';

export class AuthService {
  constructor(
    private readonly auth: Auth,
    private readonly googleProvider: AuthProvider
  ) {}

  private async handleAuthOperation(
    operation: () => Promise<UserCredential>
  ): Promise<AuthResult> {
    try {
      const result = await operation();
      // Ensure we have a user before returning success
      if (!result.user) {
        throw new Error('No user returned from authentication');
      }
      return { user: result.user, error: null };
    } catch (error: any) {
      logAuthError(error);
      return { user: null, error };
    }
  }

  async signIn(email: string, password: string): Promise<AuthResult> {
    return this.handleAuthOperation(() => 
      signInWithEmailAndPassword(this.auth, email, password)
    );
  }

  async signUp(email: string, password: string): Promise<AuthResult> {
    return this.handleAuthOperation(() => 
      createUserWithEmailAndPassword(this.auth, email, password)
    );
  }

  async signInWithGoogle(): Promise<AuthResult> {
    return this.handleAuthOperation(() => 
      signInWithPopup(this.auth, this.googleProvider)
    );
  }

  async logout(): Promise<{ error: Error | null }> {
    try {
      await signOut(this.auth);
      return { error: null };
    } catch (error: any) {
      logAuthError(error);
      return { error };
    }
  }
}