import { create } from 'zustand';
import { auth, googleProvider } from '@/lib/firebase';
import { AuthService } from '../services/authService';
import { formatAuthError } from '../utils/formatError';
import type { AuthState } from '../types';

const authService = new AuthService(auth, googleProvider);

export const useAuthStore = create<AuthState>((set) => ({
  user: null,
  isLoading: false,
  error: null,

  signIn: async (email, password) => {
    try {
      set({ isLoading: true, error: null });
      const { user, error } = await authService.signIn(email, password);
      if (error) throw error;
      set({ user });
      return user;
    } catch (error) {
      const formattedError = formatAuthError(error);
      set({ error: formattedError });
      throw error;
    } finally {
      set({ isLoading: false });
    }
  },

  signUp: async (email, password) => {
    try {
      set({ isLoading: true, error: null });
      const { user, error } = await authService.signUp(email, password);
      if (error) throw error;
      set({ user });
      return user;
    } catch (error) {
      const formattedError = formatAuthError(error);
      set({ error: formattedError });
      throw error;
    } finally {
      set({ isLoading: false });
    }
  },

  signInWithGoogle: async () => {
    try {
      set({ isLoading: true, error: null });
      const { user, error } = await authService.signInWithGoogle();
      if (error) throw error;
      set({ user });
      return user;
    } catch (error) {
      const formattedError = formatAuthError(error);
      set({ error: formattedError });
      throw error;
    } finally {
      set({ isLoading: false });
    }
  },

  logout: async () => {
    try {
      set({ isLoading: true, error: null });
      const { error } = await authService.logout();
      if (error) throw error;
      set({ user: null });
    } catch (error) {
      const formattedError = formatAuthError(error);
      set({ error: formattedError });
      throw error;
    } finally {
      set({ isLoading: false });
    }
  },

  setUser: (user) => set({ user }),
  clearError: () => set({ error: null })
}));