import React from 'react';
import { NavLink } from 'react-router-dom';
import { User, Bookmark, LogIn } from 'lucide-react';
import type { User as FirebaseUser } from 'firebase/auth';

interface UserMenuProps {
  user: FirebaseUser | null;
  onLogout: () => void;
  onShowAuth: () => void;
}

export function UserMenu({ user, onLogout, onShowAuth }: UserMenuProps) {
  if (!user) {
    return (
      <button
        onClick={onShowAuth}
        className="flex items-center gap-1.5 text-sm lg:text-base font-medium text-indigo-600 hover:text-indigo-700"
      >
        <LogIn className="w-4 h-4 lg:w-5 lg:h-5" />
        Sign In
      </button>
    );
  }

  return (
    <div className="relative group">
      <button className="flex items-center gap-2 text-sm lg:text-base font-medium text-gray-600 hover:text-gray-900">
        <User className="w-4 h-4 lg:w-5 lg:h-5" />
        {user.email?.split('@')[0]}
      </button>
      <div className="absolute right-0 mt-2 w-48 bg-white rounded-lg shadow-lg py-1 invisible group-hover:visible opacity-0 group-hover:opacity-100 transition-all duration-200">
        <NavLink
          to="/saved-tools"
          className="flex items-center gap-2 px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
        >
          <Bookmark className="w-4 h-4" />
          Saved Tools
        </NavLink>
        <button
          onClick={onLogout}
          className="w-full px-4 py-2 text-left text-sm text-red-600 hover:bg-red-50"
        >
          Sign Out
        </button>
      </div>
    </div>
  );
}