import React from 'react';
import { PenTool, Building } from 'lucide-react';
import { motion } from 'framer-motion';
import { futurePhases } from '../types';

export function FuturePhases() {
  const iconMap = {
    'pen-tool': PenTool,
    'building': Building
  };

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.3
      }
    }
  };

  const cardVariants = {
    hidden: { 
      opacity: 0,
      y: 50,
      scale: 0.95
    },
    visible: { 
      opacity: 1,
      y: 0,
      scale: 1,
      transition: {
        type: "spring",
        stiffness: 100,
        damping: 12
      }
    },
    hover: {
      scale: 1.02,
      y: -5,
      transition: {
        type: "spring",
        stiffness: 400,
        damping: 10
      }
    }
  };

  return (
    <section className="bg-gradient-to-br from-gray-900 to-indigo-900 text-white py-16 md:py-24 lg:py-32">
      <div className="container mx-auto px-4">
        <motion.div 
          className="grid grid-cols-1 md:grid-cols-2 gap-6 md:gap-8 lg:gap-12 max-w-6xl mx-auto"
          variants={containerVariants}
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, margin: "-100px" }}
        >
          {futurePhases.map((phase, index) => {
            const Icon = iconMap[phase.icon as keyof typeof iconMap];
            
            return (
              <motion.div 
                key={phase.id} 
                className="relative group"
                variants={cardVariants}
                whileHover="hover"
              >
                <div className="absolute inset-0 bg-gradient-to-br from-indigo-600/50 to-purple-600/50 rounded-2xl blur-xl opacity-50 group-hover:opacity-75 transition-opacity duration-300" />
                <div className="relative bg-white/10 backdrop-blur-sm rounded-2xl p-6 md:p-8 lg:p-10">
                  <div className="flex items-center gap-4 mb-4 md:mb-6">
                    <motion.div 
                      className="p-3 md:p-4 rounded-xl bg-white/10"
                      whileHover={{ rotate: 360 }}
                      transition={{ duration: 0.5 }}
                    >
                      <Icon className="w-6 h-6 md:w-8 md:h-8 text-white" />
                    </motion.div>
                    <h3 className="text-xl md:text-2xl lg:text-3xl font-bold bg-gradient-to-r from-white to-blue-200 bg-clip-text text-transparent">
                      {phase.id}. AI for {phase.title}
                    </h3>
                  </div>
                  <p className="text-base md:text-lg lg:text-xl text-blue-100 leading-relaxed">
                    {phase.description}
                  </p>
                </div>
              </motion.div>
            );
          })}
        </motion.div>
      </div>
    </section>
  );
}