import React from 'react';
import { HeroIcon } from './HeroIcon';
import { HeroContent } from './HeroContent';

export function HeroSection() {
  return (
    <div className="container mx-auto px-4">
      <div className="flex flex-col items-center justify-center">
        <HeroIcon />
        <HeroContent />
      </div>
    </div>
  );
}