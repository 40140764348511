import React from 'react';
import { motion } from 'framer-motion';
import { AITool } from '@/types';
import { ToolCard } from '@/components/ToolCard';
import { phaseColors } from '@/utils/phaseColors';

interface PhaseSectionProps {
  phase: string;
  tools: AITool[];
}

export function PhaseSection({ phase, tools }: PhaseSectionProps) {
  const phaseNumber = parseInt(phase.split(':')[0].split(' ')[1]);
  const colors = phaseColors[phaseNumber];
  
  return (
    <motion.section
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.6 }}
      className="rounded-xl overflow-hidden"
    >
      <div className={`px-4 py-3 ${colors.headerBg}`}>
        <div className="flex items-center justify-between">
          <h2 className={`text-lg font-bold ${colors.headerText}`}>
            {phase}
          </h2>
          <span className={`px-2 py-0.5 rounded-full text-xs font-medium ${colors.countBg} ${colors.countText}`}>
            {tools.length} {tools.length === 1 ? 'Tool' : 'Tools'}
          </span>
        </div>
        <p className={`mt-1 text-sm ${colors.descriptionText}`}>
          {getPhaseDescription(phase)}
        </p>
      </div>
      
      <div className={`p-4 bg-white border ${colors.borderColor}`}>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
          {tools.map((tool, index) => (
            <ToolCard key={tool.id} tool={tool} index={index} compact />
          ))}
        </div>
      </div>
    </motion.section>
  );
}

function getPhaseDescription(phase: string): string {
  switch (phase) {
    case 'Phase 0: Skills':
      return 'Tools for skill assessment and learning path creation';
    case 'Phase 1: Resume':
      return 'Resume building and optimization tools';
    case 'Phase 2: Job Search':
      return 'Job search and application tracking tools';
    case 'Phase 3: Networking':
      return 'Professional networking and connection tools';
    case 'Phase 4: Interview':
      return 'Interview preparation and practice tools';
    default:
      return '';
  }
}