import React from 'react';
import { motion } from 'framer-motion';
import { Bookmark, Trash2 } from 'lucide-react';

interface TabNavigationProps {
  activeTab: 'saved' | 'discarded';
  onTabChange: (tab: 'saved' | 'discarded') => void;
  savedCount: number;
  discardedCount: number;
}

export function TabNavigation({ activeTab, onTabChange, savedCount, discardedCount }: TabNavigationProps) {
  return (
    <div className="flex justify-center -mt-8 mb-8 relative z-10">
      <div className="bg-white rounded-lg shadow-lg p-1 flex gap-2">
        <motion.button
          onClick={() => onTabChange('saved')}
          className={`flex items-center gap-2 px-6 py-3 rounded-lg font-medium transition-colors ${
            activeTab === 'saved'
              ? 'bg-indigo-600 text-white'
              : 'text-gray-600 hover:bg-gray-50'
          }`}
          whileHover={{ scale: 1.02 }}
          whileTap={{ scale: 0.98 }}
        >
          <Bookmark className="w-4 h-4" />
          Saved Tools
          <span className="ml-1 px-2 py-0.5 rounded-full text-xs bg-white/20">
            {savedCount}
          </span>
        </motion.button>
        <motion.button
          onClick={() => onTabChange('discarded')}
          className={`flex items-center gap-2 px-6 py-3 rounded-lg font-medium transition-colors ${
            activeTab === 'discarded'
              ? 'bg-indigo-600 text-white'
              : 'text-gray-600 hover:bg-gray-50'
          }`}
          whileHover={{ scale: 1.02 }}
          whileTap={{ scale: 0.98 }}
        >
          <Trash2 className="w-4 h-4" />
          Discarded Tools
          <span className="ml-1 px-2 py-0.5 rounded-full text-xs bg-white/20">
            {discardedCount}
          </span>
        </motion.button>
      </div>
    </div>
  );
}