import React from 'react';
import { motion } from 'framer-motion';
import { Flame } from 'lucide-react';
import { HotPicksCarousel } from './HotPicksCarousel';

export function HotPicks() {
  return (
    <motion.section 
      className="py-6 md:py-8 lg:py-10 bg-gradient-to-br from-orange-500 via-red-500 to-yellow-500 relative overflow-hidden"
      initial={{ opacity: 0, y: 20 }}
      whileInView={{ opacity: 1, y: 0 }}
      viewport={{ once: true }}
      transition={{ duration: 0.6 }}
    >
      {/* Animated background particles */}
      <div className="absolute inset-0 overflow-hidden">
        {[...Array(3)].map((_, i) => (
          <motion.div
            key={i}
            className="absolute w-32 h-32 bg-white/5 rounded-full"
            style={{
              left: `${30 * i}%`,
              top: `${20 * (i + 1)}%`,
            }}
            animate={{
              scale: [1, 1.2, 1],
              opacity: [0.3, 0.5, 0.3],
              rotate: [0, 180, 360],
            }}
            transition={{
              duration: 8,
              repeat: Infinity,
              delay: i * 2,
              ease: "linear",
            }}
          />
        ))}
      </div>

      <div className="container mx-auto px-4 relative z-10">
        <motion.div 
          className="flex items-center justify-center gap-3 mb-4 md:mb-6"
          initial={{ opacity: 0, scale: 0.8 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{ duration: 0.4, delay: 0.2 }}
        >
          <motion.div
            className="p-2 md:p-2.5 rounded-lg bg-white/10"
            whileHover={{ scale: 1.1, rotate: 180 }}
            transition={{ duration: 0.4, type: "spring" }}
          >
            <Flame className="w-5 h-5 md:w-6 md:h-6 lg:w-7 lg:h-7 text-white" />
          </motion.div>
          <motion.h2 
            className="text-xl md:text-2xl lg:text-3xl font-bold text-white font-sans tracking-wide"
            whileHover={{ scale: 1.05 }}
            transition={{ duration: 0.2 }}
          >
            Hot Picks of the Week
          </motion.h2>
        </motion.div>
        <HotPicksCarousel />
      </div>
    </motion.section>
  );
}