export interface HotPick {
  id: string;
  name: string;
  description: string;
  url: string;
}

export const hotPicks: HotPick[] = [
  {
    id: 'textcortex',
    name: 'TextCortex',
    description: 'AI-powered writing assistant for crafting compelling resumes and cover letters that stand out.',
    url: 'https://textcortex.com/'
  },
  {
    id: 'notion',
    name: 'Notion',
    description: 'All-in-one workspace for organizing your job search and tracking applications efficiently.',
    url: 'https://www.notion.com/'
  },
  {
    id: 'jobcopilot',
    name: 'JobCopilot',
    description: 'Automated job search assistant that streamlines your application process and increases success rates.',
    url: 'https://jobcopilot.com/'
  }
];