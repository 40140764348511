import { create } from 'zustand';
import { auth, googleProvider } from '../../lib/firebase';
import {
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  signInWithPopup,
  signOut,
  type AuthError
} from 'firebase/auth';
import { formatAuthError } from './utils';
import type { AuthState } from './types';

export const useAuthStore = create<AuthState>((set) => ({
  user: null,
  isLoading: false,
  error: null,

  signIn: async (email, password) => {
    try {
      set({ isLoading: true, error: null });
      await signInWithEmailAndPassword(auth, email, password);
    } catch (error) {
      set({ error: formatAuthError(error as AuthError) });
      throw error;
    } finally {
      set({ isLoading: false });
    }
  },

  signUp: async (email, password) => {
    try {
      set({ isLoading: true, error: null });
      await createUserWithEmailAndPassword(auth, email, password);
    } catch (error) {
      set({ error: formatAuthError(error as AuthError) });
      throw error;
    } finally {
      set({ isLoading: false });
    }
  },

  signInWithGoogle: async () => {
    try {
      set({ isLoading: true, error: null });
      await signInWithPopup(auth, googleProvider);
    } catch (error) {
      set({ error: formatAuthError(error as AuthError) });
      throw error;
    } finally {
      set({ isLoading: false });
    }
  },

  logout: async () => {
    try {
      set({ isLoading: true, error: null });
      await signOut(auth);
      set({ user: null });
    } catch (error) {
      set({ error: formatAuthError(error as AuthError) });
      throw error;
    } finally {
      set({ isLoading: false });
    }
  },

  setUser: (user) => set({ user }),
  clearError: () => set({ error: null })
}));