export interface Blog {
  id: string;
  title: string;
  excerpt: string;
  category: string;
  date: string;
  image: string;
}

export const blogs: Blog[] = [
  {
    id: 'ai-job-search-2024',
    title: 'AI-Powered Job Search Strategies for 2024',
    excerpt: 'Discover how AI tools are revolutionizing the way job seekers find and land their dream positions.',
    category: 'Job Search',
    date: 'December 10, 2024',
    image: 'https://images.unsplash.com/photo-1499750310107-5fef28a66643?auto=format&fit=crop&q=80'
  },
  {
    id: 'interview-preparation',
    title: 'Master Your Interview with AI Assistance',
    excerpt: 'Learn how to leverage AI tools to prepare for interviews and increase your chances of success.',
    category: 'Interviews',
    date: 'December 10, 2024',
    image: 'https://images.unsplash.com/photo-1553877522-43269d4ea984?auto=format&fit=crop&q=80'
  },
  {
    id: 'networking-ai',
    title: 'Building Professional Networks with AI',
    excerpt: 'Explore how AI can help you create meaningful professional connections and expand your network.',
    category: 'Networking',
    date: 'December 10, 2024',
    image: 'https://images.unsplash.com/photo-1557804506-669a67965ba0?auto=format&fit=crop&q=80'
  }
];