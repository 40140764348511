import React from 'react';
import { motion } from 'framer-motion';
import { ExternalLink, Heart, Trash2 } from 'lucide-react';
import { AITool } from '../types';
import { iconMap } from '../utils/iconMap';
import { useAuthStore } from '../store/auth';
import { AuthModal } from './AuthModal';
import { DiscardConfirmDialog } from './DiscardConfirmDialog';
import { RatingStars } from '@/features/ratings/components/RatingStars';
import { useToolStore } from '@/features/tools';

interface ToolCardProps {
  tool: AITool;
  index: number;
  onToolHide?: (toolId: string) => void;
  compact?: boolean;
}

export function ToolCard({ tool, index, onToolHide, compact = false }: ToolCardProps) {
  const [showAuthModal, setShowAuthModal] = React.useState(false);
  const [showDiscardDialog, setShowDiscardDialog] = React.useState(false);
  const { user } = useAuthStore();
  const { savedTools, saveTool, hideTool } = useToolStore();
  const Icon = iconMap[tool.icon];
  
  const isSaved = savedTools.includes(tool.id);

  const handleSave = (e: React.MouseEvent) => {
    e.preventDefault();
    if (!user) {
      setShowAuthModal(true);
      return;
    }
    saveTool(tool.id);
  };

  const handleDiscard = () => {
    if (!user) {
      setShowAuthModal(true);
      return;
    }
    setShowDiscardDialog(true);
  };

  const confirmDiscard = () => {
    if (!user) return;
    hideTool(tool.id);
    onToolHide?.(tool.id);
    setShowDiscardDialog(false);
  };

  return (
    <>
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.4, delay: index * 0.1 }}
        className={`bg-white rounded-lg shadow-sm hover:shadow-md transition-shadow duration-300 ${
          compact ? 'border border-gray-100' : ''
        }`}
      >
        <div className={compact ? 'p-3' : 'p-6'}>
          <div className="flex items-start justify-between mb-3">
            <div className="flex items-center gap-2">
              <div className={`${compact ? 'p-1.5' : 'p-2'} rounded-lg bg-indigo-50`}>
                <Icon className={`${compact ? 'w-4 h-4' : 'w-5 h-5'} text-indigo-600`} />
              </div>
              <h3 className={`${compact ? 'text-base' : 'text-lg'} font-semibold text-gray-900`}>
                {tool.name}
              </h3>
            </div>
            <div className="flex items-center gap-1">
              <motion.button
                onClick={handleDiscard}
                className={`${compact ? 'p-1.5' : 'p-2'} rounded-full text-gray-400 hover:bg-gray-50 transition-colors`}
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
              >
                <Trash2 className={`${compact ? 'w-4 h-4' : 'w-5 h-5'}`} />
              </motion.button>
              <motion.button
                onClick={handleSave}
                className={`${compact ? 'p-1.5' : 'p-2'} rounded-full transition-colors ${
                  isSaved ? 'text-red-500 hover:bg-red-50' : 'text-gray-400 hover:bg-gray-50'
                }`}
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
              >
                <Heart className={`${compact ? 'w-4 h-4' : 'w-5 h-5'} ${isSaved ? 'fill-current' : ''}`} />
              </motion.button>
            </div>
          </div>

          <div className="mb-3">
            <RatingStars 
              toolId={tool.id} 
              onAuthRequired={() => setShowAuthModal(true)}
              size={compact ? 'sm' : 'md'}
            />
          </div>

          <p className={`${compact ? 'text-sm' : 'text-base'} text-gray-600 mb-3 line-clamp-2`}>
            {tool.description}
          </p>

          <div className={`space-y-1 mb-3 ${compact ? 'hidden' : 'block'}`}>
            {tool.features.map((feature, i) => (
              <div key={i} className="flex items-center gap-2 text-sm text-gray-600">
                <span className="w-1.5 h-1.5 rounded-full bg-indigo-400" />
                {feature}
              </div>
            ))}
          </div>

          <a
            href={tool.url}
            target="_blank"
            rel="noopener noreferrer"
            className={`inline-flex items-center gap-1.5 ${
              compact ? 'px-3 py-1.5 text-xs' : 'px-4 py-2 text-sm'
            } bg-indigo-600 text-white rounded-lg hover:bg-indigo-700 transition-colors`}
          >
            <span className="font-medium">Visit Tool</span>
            <ExternalLink className={compact ? 'w-3.5 h-3.5' : 'w-4 h-4'} />
          </a>
        </div>
      </motion.div>

      <AuthModal
        isOpen={showAuthModal}
        onClose={() => setShowAuthModal(false)}
      />

      <DiscardConfirmDialog
        isOpen={showDiscardDialog}
        onClose={() => setShowDiscardDialog(false)}
        onConfirm={confirmDiscard}
        toolName={tool.name}
      />
    </>
  );
}