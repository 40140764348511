import React from 'react';
import { HeroSection } from './HeroSection';

export function Header() {
  return (
    <header className="relative hero-gradient text-white">
      <div className="hero-pattern" />
      <div className="relative min-h-screen flex flex-col">
        <div className="flex-grow flex items-center justify-center py-24">
          <HeroSection />
        </div>
      </div>
    </header>
  );
}