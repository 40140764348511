import React from 'react';
import { motion } from 'framer-motion';
import { Bookmark } from 'lucide-react';
import { useToolStore } from '@/features/tools';
import { aiTools } from '@/data/tools';
import { Link } from 'react-router-dom';
import { PhaseSection } from './PhaseSection';

export function SavedToolsList() {
  const { savedTools } = useToolStore();
  const savedToolsData = aiTools.filter(tool => savedTools.includes(tool.id));

  if (savedToolsData.length === 0) {
    return (
      <motion.div 
        className="text-center py-12"
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.6 }}
      >
        <div className="flex justify-center mb-3">
          <div className="p-3 rounded-full bg-gray-100">
            <Bookmark className="w-6 h-6 text-gray-400" />
          </div>
        </div>
        <h3 className="text-lg font-semibold text-gray-900 mb-2">No saved tools yet</h3>
        <p className="text-sm text-gray-600 mb-4">Start exploring and save the tools that interest you.</p>
        <Link
          to="/"
          className="inline-flex items-center px-3 py-1.5 text-sm bg-indigo-600 text-white rounded-lg hover:bg-indigo-700 transition-colors"
        >
          Explore Tools
        </Link>
      </motion.div>
    );
  }

  const toolsByPhase = savedToolsData.reduce((acc, tool) => {
    const phase = tool.category;
    if (!acc[phase]) acc[phase] = [];
    acc[phase].push(tool);
    return acc;
  }, {} as Record<string, typeof savedToolsData>);

  return (
    <div className="space-y-6">
      {Object.entries(toolsByPhase)
        .sort(([a], [b]) => {
          const phaseA = parseInt(a.split(':')[0].split(' ')[1]);
          const phaseB = parseInt(b.split(':')[0].split(' ')[1]);
          return phaseA - phaseB;
        })
        .map(([phase, tools]) => (
          <PhaseSection key={phase} phase={phase} tools={tools} />
        ))}
    </div>
  );
}