import React from 'react';
import { motion } from 'framer-motion';
import { Rocket } from 'lucide-react';

interface LogoProps {
  onNavigate: () => void;
}

export function Logo({ onNavigate }: LogoProps) {
  return (
    <motion.div
      onClick={onNavigate}
      whileHover={{ scale: 1.05 }}
      whileTap={{ scale: 0.95 }}
      className="p-1.5 sm:p-2 rounded-lg bg-gradient-to-r from-indigo-500 to-blue-500 cursor-pointer"
    >
      <Rocket className="w-3.5 h-3.5 sm:w-4 sm:h-4 md:w-5 md:h-5 text-white" />
    </motion.div>
  );
}