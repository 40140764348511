import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { Bookmark, Trash2 } from 'lucide-react';
import { useToolStore } from '@/features/tools';
import { Footer } from '@/components/Footer';
import { TabNavigation } from '@/components/SavedTools/TabNavigation';
import { SavedToolsList } from '@/components/SavedTools/SavedToolsList';
import { DiscardedToolsList } from '@/components/SavedTools/DiscardedToolsList';

type Tab = 'saved' | 'discarded';

export function SavedToolsPage() {
  const [activeTab, setActiveTab] = useState<Tab>('saved');
  const { savedTools, hiddenTools } = useToolStore();

  return (
    <div className="min-h-screen bg-gradient-to-br from-gray-50 to-gray-100">
      <div className="pt-20">
        <motion.div 
          className="py-12 bg-gradient-to-br from-indigo-600 to-blue-600 text-white relative overflow-hidden"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.6 }}
        >
          <div className="absolute inset-0 pattern-bg opacity-20" />
          <div className="container mx-auto px-4 relative">
            <div className="flex flex-col items-center text-center">
              <motion.div 
                className="p-3 rounded-lg bg-white/10 mb-4 backdrop-blur-sm"
                whileHover={{ scale: 1.05 }}
                transition={{ duration: 0.2 }}
              >
                {activeTab === 'saved' ? (
                  <Bookmark className="w-6 h-6 text-white" />
                ) : (
                  <Trash2 className="w-6 h-6 text-white" />
                )}
              </motion.div>
              <h1 className="text-3xl md:text-5xl font-bold mb-4">
                {activeTab === 'saved' ? 'Saved Tools' : 'Discarded Tools'}
              </h1>
              <p className="text-lg md:text-xl text-blue-100 max-w-2xl mx-auto">
                {activeTab === 'saved' 
                  ? 'Your personalized collection of AI tools for career development.'
                  : 'Tools you\'ve hidden. You can restore them at any time.'}
              </p>
            </div>
          </div>
        </motion.div>

        <div className="container mx-auto px-4">
          <TabNavigation 
            activeTab={activeTab} 
            onTabChange={setActiveTab} 
            savedCount={savedTools.length}
            discardedCount={hiddenTools.length}
          />

          <div className="py-8 mt-8">
            {activeTab === 'saved' ? (
              <SavedToolsList />
            ) : (
              <DiscardedToolsList />
            )}
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
}