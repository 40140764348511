import { z } from 'zod';
import type { User } from 'firebase/auth';

// Schema definitions
const ratingSchema = z
  .number()
  .int()
  .min(1, 'Rating must be at least 1')
  .max(5, 'Rating cannot be higher than 5');

const toolIdSchema = z
  .string()
  .min(1, 'Tool ID is required');

// Validation functions
export function validateRating(rating: number): boolean {
  try {
    ratingSchema.parse(rating);
    return true;
  } catch {
    return false;
  }
}

export function validateRatingSubmission(
  toolId: string,
  rating: number,
  user: User | null
): { isValid: boolean; error?: string } {
  if (!user) {
    return { isValid: false, error: 'User must be logged in to rate' };
  }

  try {
    toolIdSchema.parse(toolId);
    ratingSchema.parse(rating);
    return { isValid: true };
  } catch (error) {
    if (error instanceof z.ZodError) {
      return { isValid: false, error: error.errors[0].message };
    }
    return { isValid: false, error: 'Invalid rating submission' };
  }
}