import React from 'react';
import { motion } from 'framer-motion';
import { Lightbulb, FileText, Search, Handshake, MessageSquare } from 'lucide-react';
import { phaseDescriptions } from '../types';

interface CategoryFilterProps {
  categories: string[];
  selectedCategory: string | null;
  onSelectCategory: (category: string | null) => void;
}

const categoryIcons = {
  'Phase 0: Skills': Lightbulb,
  'Phase 1: Resume': FileText,
  'Phase 2: Job Search': Search,
  'Phase 3: Networking': Handshake,
  'Phase 4: Interview': MessageSquare,
};

export function CategoryFilter({ categories, selectedCategory, onSelectCategory }: CategoryFilterProps) {
  const uniqueCategories = Array.from(new Set(categories))
    .filter(category => category !== 'Coming Soon')
    .sort((a, b) => {
      const phaseA = parseInt(a.split(':')[0].split(' ')[1]);
      const phaseB = parseInt(b.split(':')[0].split(' ')[1]);
      return phaseA - phaseB;
    });

  return (
    <div className="py-6 md:py-8 bg-white border-b border-gray-100">
      <div className="container mx-auto px-4">
        <div className="flex flex-wrap items-center justify-center gap-2 md:gap-4">
          <motion.button
            onClick={() => onSelectCategory(null)}
            className={`w-full sm:w-auto min-w-[100px] px-4 md:px-6 py-2 md:py-3 rounded-lg text-sm md:text-base font-medium transition-all duration-200 ${
              selectedCategory === null
                ? 'bg-indigo-600 text-white shadow-md'
                : 'bg-gray-50 text-gray-700 hover:bg-gray-100'
            }`}
            whileHover={{ scale: 1.02 }}
            whileTap={{ scale: 0.98 }}
          >
            All Tools
          </motion.button>
          
          {uniqueCategories.map((category) => {
            const Icon = categoryIcons[category as keyof typeof categoryIcons];
            const description = phaseDescriptions[category];
            
            return (
              <div key={category} className="group relative w-full sm:w-auto">
                <motion.button
                  onClick={() => onSelectCategory(category)}
                  className={`w-full sm:w-auto min-w-[100px] px-4 md:px-6 py-2 md:py-3 rounded-lg text-sm md:text-base font-medium transition-all duration-200 flex items-center justify-center gap-2 ${
                    selectedCategory === category
                      ? 'bg-indigo-600 text-white shadow-md'
                      : 'bg-gray-50 text-gray-700 hover:bg-gray-100'
                  }`}
                  whileHover={{ scale: 1.02 }}
                  whileTap={{ scale: 0.98 }}
                >
                  {Icon && <Icon className="w-4 h-4 md:w-5 md:h-5" />}
                  {description?.name || category}
                </motion.button>
                
                {description && (
                  <div className="tooltip absolute left-1/2 -translate-x-1/2 bottom-full mb-2 bg-gray-900 text-white rounded-lg opacity-0 invisible group-hover:opacity-100 group-hover:visible transition-all duration-200 z-10 w-40 sm:w-56">
                    <div className="relative p-2 text-xs md:text-sm">
                      {description.description}
                      <div className="absolute -bottom-1 left-1/2 -translate-x-1/2 w-0 h-0 border-4 border-transparent border-t-gray-900" />
                    </div>
                  </div>
                )}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}