import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { aiTools } from '@/data/tools';
import type { ToolState, ToolActions } from '../types';

interface ToolStore extends ToolState, ToolActions {}

export const useToolStore = create<ToolStore>()(
  persist(
    (set, get) => ({
      tools: aiTools,
      selectedCategory: null,
      hiddenTools: [],
      savedTools: [],
      toolRatings: {},

      saveTool: (toolId) => {
        const { savedTools } = get();
        const isToolSaved = savedTools.includes(toolId);

        if (isToolSaved) {
          set({ savedTools: savedTools.filter(id => id !== toolId) });
        } else {
          set({ savedTools: [...savedTools, toolId] });
        }
      },

      hideTool: (toolId) => {
        const { hiddenTools } = get();
        if (!hiddenTools.includes(toolId)) {
          set({ hiddenTools: [...hiddenTools, toolId] });
        }
      },

      restoreTool: (toolId) => {
        const { hiddenTools } = get();
        set({
          hiddenTools: hiddenTools.filter(id => id !== toolId)
        });
      },

      setSelectedCategory: (category) => {
        set({ selectedCategory: category });
      },

      rateTool: (toolId, rating) => {
        const { toolRatings } = get();
        set({
          toolRatings: {
            ...toolRatings,
            [toolId]: rating
          }
        });
      }
    }),
    {
      name: 'tool-storage',
      partialize: (state) => ({
        hiddenTools: state.hiddenTools,
        savedTools: state.savedTools,
        toolRatings: state.toolRatings
      })
    }
  )
);