import React from 'react';
import { motion } from 'framer-motion';
import { RefreshCw, ExternalLink } from 'lucide-react';
import { AITool } from '@/types';
import { iconMap } from '@/utils/iconMap';
import { useToolStore } from '@/features/tools';

interface DiscardedToolCardProps {
  tool: AITool;
  index: number;
}

export function DiscardedToolCard({ tool, index }: DiscardedToolCardProps) {
  const { restoreTool } = useToolStore();
  const Icon = iconMap[tool.icon];

  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.4, delay: index * 0.1 }}
      className="bg-white rounded-lg shadow-sm hover:shadow-md transition-shadow duration-300 border border-gray-100"
    >
      <div className="p-4">
        <div className="flex items-start justify-between mb-3">
          <div className="flex items-center gap-2">
            <div className="p-1.5 rounded-lg bg-indigo-50">
              <Icon className="w-4 h-4 text-indigo-600" />
            </div>
            <h3 className="text-base font-semibold text-gray-900">
              {tool.name}
            </h3>
          </div>
          <motion.button
            onClick={() => restoreTool(tool.id)}
            className="p-1.5 rounded-full text-emerald-500 hover:bg-emerald-50 transition-colors"
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            title="Restore tool"
          >
            <RefreshCw className="w-4 h-4" />
          </motion.button>
        </div>

        <p className="text-sm text-gray-600 mb-3 line-clamp-2">
          {tool.description}
        </p>

        <a
          href={tool.url}
          target="_blank"
          rel="noopener noreferrer"
          className="inline-flex items-center gap-1.5 px-3 py-1.5 text-xs bg-indigo-600 text-white rounded-lg hover:bg-indigo-700 transition-colors"
        >
          <span className="font-medium">Visit Tool</span>
          <ExternalLink className="w-3.5 h-3.5" />
        </a>
      </div>
    </motion.div>
  );
}