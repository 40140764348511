import { LucideIcon } from 'lucide-react';

export interface AITool {
  id: string;
  name: string;
  description: string;
  category: 'Phase 0: Skills' | 'Phase 1: Resume' | 'Phase 2: Job Search' | 'Phase 3: Networking' | 'Phase 4: Interview' | 'Coming Soon';
  icon: string;
  url: string;
  features: string[];
  phase: number;
}

export interface SavedTool extends AITool {
  note?: string;
  rating?: number;
  hidden?: boolean;
}

export interface PhaseInfo {
  name: string;
  description: string;
}

export const phaseDescriptions: Record<string, PhaseInfo> = {
  'Phase 0: Skills': {
    name: 'Phase 0: Skill Building',
    description: 'AI tools for skill assessment and learning paths.'
  },
  'Phase 1: Resume': {
    name: 'Phase 1: Resume Crafting',
    description: 'Create ATS-friendly resumes with AI optimization.'
  },
  'Phase 2: Job Search': {
    name: 'Phase 2: Targeted Job Search',
    description: 'Match skills to relevant job opportunities.'
  },
  'Phase 3: Networking': {
    name: 'Phase 3: Strategic Networking',
    description: 'Connect with key professionals in your field.'
  },
  'Phase 4: Interview': {
    name: 'Phase 4: Interview Mastery',
    description: 'Practice interviews with AI feedback.'
  }
};

export interface FuturePhase {
  id: number;
  title: string;
  description: string;
  icon: string;
}

export const futurePhases: FuturePhase[] = [
  {
    id: 1,
    title: 'Content Creation',
    description: 'Streamline your creative process. Discover tools for editing, designing, and automating content creation workflows.',
    icon: 'pen-tool'
  },
  {
    id: 2,
    title: 'Small Business Solutions',
    description: 'Simplify operations and scale smarter. Explore AI tools for invoicing, marketing, and process automation.',
    icon: 'building'
  }
];