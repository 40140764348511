import { AITool } from '../../types';

export const phase0Tools: AITool[] = [
  {
    id: 'skillai',
    name: 'SkillAI',
    description: 'SkillAI is an AI-powered platform that helps users develop professional skills efficiently by generating customized learning paths for any skill.',
    category: 'Phase 0: Skills',
    icon: 'lightbulb',
    url: 'https://skillai.io',
    features: [
      'Personalized learning path generation',
      'Community-shared learning paths',
      'Progress tracking'
    ],
    phase: 0
  },
  {
    id: '7seers',
    name: '7Seers',
    description: '7Seers offers AI-driven solutions for skill gap analysis, providing personalized learning paths and AI-generated lecture content.',
    category: 'Phase 0: Skills',
    icon: 'lightbulb',
    url: 'https://7seers.ai',
    features: [
      'AI-powered skill gap analysis',
      'Personalized learning paths',
      'AI-generated lecture content'
    ],
    phase: 0
  },
  {
    id: 'khanmigo',
    name: 'Khan Academy\'s Khanmigo',
    description: 'Khanmigo is an AI-powered tutor developed by Khan Academy, offering personalized tutoring and immediate feedback.',
    category: 'Phase 0: Skills',
    icon: 'lightbulb',
    url: 'https://www.khanacademy.org/khan-labs',
    features: [
      'Personalized tutoring',
      'Immediate feedback',
      'Lesson planning assistance'
    ],
    phase: 0
  },
  {
    id: 'pearson-channels',
    name: 'Pearson+ Channels AI',
    description: 'Pearson has integrated AI learning tools into its Pearson+ Channels platform, providing personalized study experiences.',
    category: 'Phase 0: Skills',
    icon: 'lightbulb',
    url: 'https://www.pearson.com/pearsonplus.html',
    features: [
      'AI-generated study plans',
      'AI tutors for specific problems',
      'Video content integration'
    ],
    phase: 0
  },
  {
    id: 'aipathly',
    name: 'AiPathly',
    description: 'AiPathly offers personalized skill analysis, learning paths, and career recommendations.',
    category: 'Phase 0: Skills',
    icon: 'lightbulb',
    url: 'https://www.aipathly.com',
    features: [
      'Personalized skill analysis',
      'Customized learning paths',
      'Career recommendations'
    ],
    phase: 0
  }
];