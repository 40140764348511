export const phaseColors = {
  0: {
    headerBg: 'bg-blue-50',
    headerText: 'text-blue-900',
    descriptionText: 'text-blue-700',
    countBg: 'bg-blue-100',
    countText: 'text-blue-800',
    borderColor: 'border-blue-100',
    buttonBg: 'bg-blue-600 hover:bg-blue-700',
    buttonText: 'text-white'
  },
  1: {
    headerBg: 'bg-emerald-50',
    headerText: 'text-emerald-900',
    descriptionText: 'text-emerald-700',
    countBg: 'bg-emerald-100',
    countText: 'text-emerald-800',
    borderColor: 'border-emerald-100',
    buttonBg: 'bg-emerald-600 hover:bg-emerald-700',
    buttonText: 'text-white'
  },
  2: {
    headerBg: 'bg-amber-50',
    headerText: 'text-amber-900',
    descriptionText: 'text-amber-700',
    countBg: 'bg-amber-100',
    countText: 'text-amber-800',
    borderColor: 'border-amber-100',
    buttonBg: 'bg-amber-600 hover:bg-amber-700',
    buttonText: 'text-white'
  },
  3: {
    headerBg: 'bg-orange-50',
    headerText: 'text-orange-900',
    descriptionText: 'text-orange-700',
    countBg: 'bg-orange-100',
    countText: 'text-orange-800',
    borderColor: 'border-orange-100',
    buttonBg: 'bg-orange-600 hover:bg-orange-700',
    buttonText: 'text-white'
  },
  4: {
    headerBg: 'bg-red-50',
    headerText: 'text-red-900',
    descriptionText: 'text-red-700',
    countBg: 'bg-red-100',
    countText: 'text-red-800',
    borderColor: 'border-red-100',
    buttonBg: 'bg-red-600 hover:bg-red-700',
    buttonText: 'text-white'
  }
} as const;