import React from 'react';
import { motion } from 'framer-motion';
import { Sparkles } from 'lucide-react';
import { Footer } from '../components/Footer';

export function AboutPage() {
  return (
    <div className="min-h-screen bg-gray-50 pt-16">
      <motion.div 
        className="py-12 md:py-16 bg-gradient-to-br from-indigo-600 to-blue-600 text-white"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.6 }}
      >
        <div className="container mx-auto px-4">
          <div className="flex flex-col items-center text-center">
            <motion.div 
              className="p-2 rounded-lg bg-white/10 mb-4"
              whileHover={{ scale: 1.05 }}
              transition={{ duration: 0.2 }}
            >
              <Sparkles className="w-5 h-5 text-white" />
            </motion.div>
            <h1 className="text-2xl md:text-4xl font-bold">About Us</h1>
            <p className="mt-4 text-sm md:text-lg text-blue-100 max-w-2xl mx-auto leading-relaxed">
              We're building a dynamic platform to simplify AI adoption for everyone. From job seekers to content creators and small business owners, our goal is to provide curated AI tools and resources that evolve with your needs.
            </p>
          </div>
        </div>
      </motion.div>

      <section className="py-12 md:py-20">
        <div className="container mx-auto px-4">
          <div className="max-w-4xl mx-auto grid gap-8">
            <motion.div
              className="bg-white rounded-xl p-6 md:p-8 shadow-sm"
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ duration: 0.6 }}
            >
              <h2 className="text-xl md:text-2xl font-bold text-gray-900 mb-3">Our Mission</h2>
              <p className="text-sm md:text-base text-gray-600 leading-relaxed">
                To democratize access to AI tools and empower individuals to achieve their professional goals through innovative technology solutions.
              </p>
            </motion.div>

            <motion.div
              className="bg-white rounded-xl p-6 md:p-8 shadow-sm"
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ duration: 0.6, delay: 0.2 }}
            >
              <h2 className="text-xl md:text-2xl font-bold text-gray-900 mb-3">Our Vision</h2>
              <p className="text-sm md:text-base text-gray-600 leading-relaxed">
                To create a world where AI technology is accessible, understandable, and beneficial to everyone's professional journey.
              </p>
            </motion.div>
          </div>
        </div>
      </section>

      <Footer />
    </div>
  );
}