import React from 'react';
import { CategoryFilter } from '../CategoryFilter';
import { MainContent } from '../MainContent';
import { useToolStore } from '@/features/tools';
import { aiTools } from '@/data/tools';

export function ToolsSection() {
  const { selectedCategory, setSelectedCategory, hiddenTools } = useToolStore();
  
  // Get all visible tools (not hidden)
  const visibleTools = React.useMemo(() => {
    return aiTools.filter(tool => !hiddenTools.includes(tool.id));
  }, [hiddenTools]);

  // Get all categories from visible tools
  const categories = React.useMemo(() => 
    Array.from(new Set(visibleTools.map(tool => tool.category)))
      .filter(category => category !== 'Coming Soon')
      .sort((a, b) => {
        const phaseA = parseInt(a.split(':')[0].split(' ')[1]);
        const phaseB = parseInt(b.split(':')[0].split(' ')[1]);
        return phaseA - phaseB;
      }),
    [visibleTools]
  );

  // Filter tools by selected category
  const filteredTools = React.useMemo(() => {
    if (selectedCategory) {
      return visibleTools.filter(tool => tool.category === selectedCategory);
    }
    return visibleTools;
  }, [visibleTools, selectedCategory]);

  return (
    <section className="py-12 md:py-16 lg:py-20">
      <div className="container mx-auto px-4 mb-12">
        <h2 className="text-2xl md:text-4xl lg:text-5xl font-bold text-gray-900 text-center">
          AI Tools for Career Development
        </h2>
        <p className="mt-3 md:mt-6 text-base md:text-xl lg:text-2xl text-gray-600 text-center max-w-4xl mx-auto">
          Explore our curated collection of AI-powered tools designed to enhance every stage of your professional journey.
        </p>
      </div>

      <CategoryFilter
        categories={categories}
        selectedCategory={selectedCategory}
        onSelectCategory={setSelectedCategory}
      />
      
      <MainContent tools={filteredTools} />
    </section>
  );
}